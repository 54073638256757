.calc-container {
    margin: auto;
    height: 45vh; /* 根据需要调整高度 */
    width: 35vh;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 设置 4 列 */
    grid-template-rows: repeat(4, 1fr); /* 设置 4 行 */
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    text-align: center;
    background-color: #c0c0c0; /* 背景色 */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 15px;
}

.calc-input {
    border: none;
    width: 70%;
    text-align: right;
    padding: 10px;
    border-radius: 5px; /* 输入框圆角 */
    background-color: #ffffff; /* 输入框背景色 */
    font-size: larger;
}

.digit {
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    padding: 10px;
    border-radius: 5px;
    font-size: larger;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #eaeaea; /* 默认按钮背景色 */
    border: none;
    margin: 4px;
    width: 52px;
    height: 40px;
    transition: background-color 0.3s ease; /* 动态效果过渡 */
}

    /* 设置第一行所有按钮的背景色 */
    .digit.execute { /* 选择前 4 个按钮 (第一行) */
        background-color: #ff7f50; /* 浅珊瑚色 */
        color: white; /* 白色文字 */
    }

        .digit.execute:hover { /* 第一行的 hover 背景 */
            background-color: #ff4500; /* 橙红色 hover */
            color: white; /* 白色文字 */
        }

    /* 设置每行的最后一个按钮的深色背景（由右数） */
    .digit:nth-child(4n) { /* 每行的最后一个按钮 */
        background-color: #ff7f50; /* 浅珊瑚色 */
    }

    /* 鼠标悬停时的样式 */
    .digit:hover {
        background-color: #d3d3d3; /* 按钮悬停背景颜色 */
    }

    /* 最后一个按钮的 hover 动态效果 */
    .digit:nth-child(4n):hover {
        background-color: #ff4500; /* 橙红色 hover */
    }

    /* 为相应的 active 状态添加样式 */
    .digit:active {
        background-color: #b22222; /* 深红色 active 背景 */
    }

    .digit:nth-child(-n+4):active {
        background-color: #ff4500; /* 第一行 active 背景 */
    }

    .digit:nth-child(4n):active {
        background-color: #d32f2f; /* 每行最后一个按钮的 active 背景颜色 */
    }
